import { TermsProps } from '../../@interface/terms'

export const dataTermsCampaignLimit: TermsProps = {
  title:
    'Termos e Condições da Campanha Indique e Ganhe Limite no Cartão de Crédito',
  subtitle: 'da Campanha Indique e Ganhe Limite no Cartão de Crédito',
  update: '01 de outubro de 2021',
  about: {
    title: 'Termo',
    introduction: `
    A campanha de Indicação para Aumento de Limite do Cartão de Crédito é realizada
    pela Cora Sociedade de Crédito, Financiamento e Investimento S.A., inscrita no CNPJ sob o nº
    37.880.206/0001-63, com sede na Cidade de São Paulo, <br/>Estado de São Paulo, Rua Frei Caneca, 1246, 3º andar, sala 31 - Consolação - São Paulo - SP – CEP
    04547-006, doravante denominada simplesmente “Cora”.
    <br/>
    Para os fins desta campanha os seguintes termos possuirão os seguintes significados: <br /><br />
    <b> Aplicativo Cora: </b>
    <br />
    Aplicativo mobile disponibilizado pela Cora para que você possa gerenciar a
    Conta Cora de sua empresa.
    <br /><br />
    <b> Aumento de Limite do Cartão de Crédito: </b>
    <br />
    Cada indicação válida de um (01) CNPJ consiste no aumento do limite do crédito
    do Cliente Cora que indicou no valor de <b>R$ 100,00</b> (cem reais). Este aumento de
    limite está condicionado ao atendimento de todas as condições previstas nesta
    campanha.
    <br /><br />
    <b> Cliente Cora: </b>
    <br />
    Todo aquele Usuário do Aplicativo Cora, que possua cadastro ativo e regular e
    que utilizou o link disponibilizado no Aplicativo Cora para a indicação. O
    conceito também se aplica a expressão <b>“Usuário”</b>.
    <br /><br />
    <b> Empresa Indicada: </b>
    <br />
    Todo aquele Novo Usuário que for indicado através de link especial, fornecido
    pelo Cliente Cora, que se cadastre e faça uma movimentação em sua nova conta
    Cora. O conceito também se aplica a expressão <b>“Novo Usuário”</b> e <b>“Empresa(s)”</b>.<br />
    Fica entendido que Empresa Indicada representa a indicação de um (01) CNPJ pelo
    Cliente Cora, logo caso duas pessoas sócias de uma Empresa Indicada criarem
    cadastro na Cora, será contabilizado apenas uma (01) indicação para a campanha.
    <br />
    <b> Indicação Válida: </b>
    <br />Cadastro da Empresa Indicada, ou seja, um (01) CNPJ cadastrado pela
    primeira vez, através do link recebido pelo Usuário e a realização de uma (01)
    movimentação em sua nova conta Cora.
    <br /><br />
    <b>Ao participar da campanha, você concorda com o presente Termos e Condições da Campanha Indique e Ganhe Limite no Cartão de Crédito.</b>
    `,
  },
  section: [
    {
      list: [
        {
          head: 'Do objeto da campanha',
          items: [
            {
              item: `A campanha consiste no Cliente Cora indicar 10 (dez) Empresas e ser concedido, ao Cliente Cora, o Aumento de Limite do Cartão de Crédito em R$ 100,00 (cem reais) por cada Indicação Válida, ou seja, desde que esta Empresa se torne um Novo Usuário.`,
            },
            {
              item: `O Cliente Cora poderá acompanhar, por meio do Aplicativo Cora, a evolução das indicações e o Aumento de Limite do Cartão de Crédito correspondente, que será feito em até 07 (sete) dias úteis, após verificação do atendimento dos requisitos elencados na Cláusula 2, observado o prazo de aprovação do aumento de crédito indicado na Cláusula 5.2.`,
            },
          ],
        },
        {
          head: 'Condições para a participação',
          items: [
            {
              item: `
              São condições cumulativas para participação na campanha:
              <br/>
              <b>a)</b> Estar de acordo com os <a href="/termos-uso" alt="Termos e Condições de Uso">Termos e Condições de Uso</a>, <a href="/termos-de-uso-cartao-de-credito" alt="Termos de Uso do Cartão de Crédito">Termos de Uso do Cartão de Crédito</a> e <a href="/politica-privacidade" alt="Política de Privacidade da Cora">Política de Privacidade da Cora</a>;
              <br/>
              <b>b)</b> O Cliente Cora estar com o seus dados junto ao cadastro no Aplicativo Cora corretos e atualizados;
              <br/>
              <b>c)</b> Ser Cliente Cora já cadastrado e utilizar o link de indicação da campanha disponibilizado no Aplicativo Cora;
              <br/>
              <b>d)</b> Indicar uma Empresa que não seja Cliente Cora, ou seja, um Novo Usuário;<br/>
              <b>e)</b> Que a Empresa Indicada se cadastre pela primeira vez, através do link recebido pelo Usuário e realize uma movimentação em sua nova conta Cora, considerando-se então como indicação válida.
              <br/>
              Importante esclarecer que o próprio Cliente Cora é quem deverá encaminhar o link à Empresa que pretende indicar.
              `,
            },
          ],
        },
        {
          head: 'Do prazo e da área de execução da campanha',
          items: [
            {
              item: `Esta campanha é válida a partir do dia 28 de Março de 2022 até o dia 22 de Abril de 2022 em todo o território nacional, ou seja, serão contabilizadas para cumprir os critérios do Aumento de Limite do Cartão de Crédito apenas as Indicações Válidas que criarem conta na Cora e realizarem uma (01) movimentação durante o período.`,
            },
          ],
        },
        {
          head: 'Restrições da campanha',
          items: [
            {
              item: `Não será devido o aumento do limite de crédito se:
              <br/>
              <b>a)</b> A Empresa Indicada não se cadastrar e não realizar uma (01) movimentação na nova conta Cora;
              <br/>
              <b>b)</b> A Empresa Indicada já integrar a base de clientes da Cora, ou seja, o CNPJ indicado já constar na base antes da indicação.
              `,
            },
            {
              item: `Importante esclarecer que para que a indicação seja considerada válida, o Cliente Cora deve encaminhar à Empresa Indicada o link disponibilizado no Aplicativo Cora para a indicação.`,
            },
            {
              item: `Não será devido Aumento de Limite do Cartão de Crédito, caso fique constatada fraude na campanha, como por exemplo, mas não se limitando, ao cadastro indevido da Empresa Indicada, feito com dados de terceiro que não estava de acordo com esse cadastro.`,
            },
          ],
        },
        {
          head: 'Da apuração do aumento de limite do cartão de crédito',
          items: [
            {
              item: `Para os Usuários que atenderem as condições da Cláusula 2, será concedido o aumento do limite de crédito no valor de R$ 100,00 (cem reais) por indicação válida, após apuração realizada pela Cora. É importante ressaltar que o Aumento de Limite do Cartão de Crédito é devido apenas ao Cliente Cora que faz a indicação válida, e não à Empresa Indicada.`,
            },
            {
              item: `A apuração do Aumento de Limite do Cartão de Crédito será feita em até 07 (sete) dias úteis após a indicação válida da Empresa Indicada, conforme Cláusula 2.`,
            },
            {
              item: `O Cliente Cora reconhece que a Cora não poderá ser responsabilizada por eventual atraso do Aumento de Limite do Cartão de Crédito, causado pela própria Empresa Indicada, como, por exemplo, mas não se limitando, em razão do cadastro de dados incorretos ou não aceitáveis ou outras inconsistências cadastrais.`,
            },
            {
              item: `O Aumento do Limite do Cartão Crédito conquistado com as indicações, bem como qualquer outro limite disponível na conta Cora, poderá ser cessado em casos de:
              <br/>
              <b>a)</b> Atraso e/ou inadimplência do pagamento da fatura;
              <br/>
              <b>b)</b> Uso da Conta Cora para atividades ilegais e/ou suspeitas;
              <br/>
              <b>c)</b> Inatividade do uso da Conta Cora e do Cartão Cora por mais de 12 (doze)</b> meses;
              <br/>
              <b>d)</b> Encerramento da Conta Cora;
              <br/>
              <b>e)</b> Ou qualquer atividade em desacordo com os <a href="/termos-uso" alt="Termos de Uso da Conta">Termos de Uso da Conta</a> e <a href="/termos-de-uso-cartao-de-credito" alt="Termos de Uso do Cartão de Crédito">Termos de Uso do Cartão de Crédito</a>.`,
            },
          ],
        },
      ],
    },
  ],
}
