import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import TermsPages from '../partials/Terms/About'
import { dataTermsCampaignLimit } from '../data/terms/terms-promo-campaign-limit'

const TermsCampaignLimit = () => (
  <>
    <GatsbySeo nofollow={true} noindex={true} />
    <TermsPages
      data={dataTermsCampaignLimit}
      linkCanonical="termos-campanha-aumente-seu-limite"
    />
  </>
)

export default TermsCampaignLimit
